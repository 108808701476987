import React, { useEffect, useState, useCallback } from "react";

import { getFile } from "../store/actions/file";

const PublicidadeBanner = ({ data, type }) => { // Recebe a propriedade type
  const [image, setImage] = useState("");

  const onFetchImage = useCallback(async (id) => {
    const response = await getFile(id);
    if (response) setImage(response);
  }, []);

  useEffect(() => {
    data && onFetchImage(data.cover);
  }, [data, onFetchImage]);


  return (
    <a href={data.site}>
      <img
        alt={data.name}
        className={`ui centered image ${type} banner`} // Utiliza a propriedade type
        src={image}
        style={{
          marginTop: "1em",
          marginBottom: "1em",
          border: "1px solid #ccc", // Borda fina
          borderRadius: "4px",
        }}
      />
    </a>
  );
};

export default PublicidadeBanner;
