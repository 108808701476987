// Menu
export const OPEN_MENU = "OPEN_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
// Modal
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_SELECTED_ITEM = "SET_SELECTED_ITEM";
// Auth
export const LOADING_ME = "LOADING_ME";
export const ERROR_ME = "ERROR_ME";
export const SIGN_UP = "SIGN_UP";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOFF = "USER_LOGOFF"
export const FETCH_ME = "FETCH_ME";
export const EDIT_ME = "EDIT_ME";
export const DISABLE_ME = "DISABLE_ME";
// User
export const LOADING_USER = "LOADING_USER";
export const ERROR_USER = "ERROR_USER";
export const CREATE_USER = "CREATE_USER";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USERS = "FETCH_USERS";
export const EDIT_USER = "EDIT_USER";
export const DISABLE_USER = "DISABLE_USER";
// Advertisement
export const LOADING_ADVERTISEMENT = "LOADING_ADVERTISEMENT";
export const ERROR_ADVERTISEMENT = "ERROR_ADVERTISEMENT";
export const CREATE_ADVERTISEMENT = "CREATE_ADVERTISEMENT";
export const FETCH_ADVERTISEMENT = "FETCH_ADVERTISEMENT";
export const FETCH_ADVERTISEMENTS = "FETCH_ADVERTISEMENTS";
export const FETCH_ADVERTISEMENTS_IN_REVIEW = "FETCH_ADVERTISEMENTS_IN_REVIEW";
export const FETCH_FEATURED_ADVERTISEMENTS = "FETCH_FEATURED_ADVERTISEMENTS";
export const FETCH_IN_REGION_SALE_ADVERTISEMENTS = "FETCH_IN_REGION_SALE_ADVERTISEMENTS";
export const EDIT_ADVERTISEMENT = "EDIT_ADVERTISEMENT";
export const DISABLE_ADVERTISEMENT = "DISABLE_ADVERTISEMENT";
export const REVIEW_ADVERTISEMENT = "REVIEW_ADVERTISEMENT";
// Category
export const LOADING_CATEGORY = "LOADING_CATEGORY";
export const ERROR_CATEGORY = "ERROR_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DISABLE_CATEGORY = "DISABLE_CATEGORY";

export const FETCH_SUBCATEGORY = "FETCH_SUBCATEGORY";
export const CREATE_SUBCATEGORY = "CREATE_SUBCATEGORY";
export const EDIT_SUBCATEGORY = "CREATE_SUBCATEGORY";
// Location
export const LOADING_LOCATION = "LOADING_LOCATION";
export const ERROR_LOCATION = "ERROR_LOCATION";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const FETCH_LOCATION = "FETCH_LOCATION";
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const DISABLE_LOCATION = "DISABLE_LOCATION";
export const FETCH_BANNERS_BY_LOCATION = 'FETCH_BANNERS_BY_LOCATION';
// File
export const LOADING_FILE = "LOADING_FILE";
export const ERROR_FILE = "ERROR_FILE";
export const UPLOAD_FILE = "UPLOAD_FILE";
// Quotation
export const LOADING_QUOTATION = "LOADING_QUOTATION";
export const ERROR_QUOTATION = "ERROR_QUOTATION";
export const CREATE_QUOTATION = "CREATE_QUOTATION";
export const FETCH_QUOTATION = "FETCH_QUOTATION";
export const FETCH_QUOTATIONS = "FETCH_QUOTATIONS";
export const EDIT_QUOTATION = "EDIT_QUOTATION";
export const DISABLE_QUOTATION = "DISABLE_QUOTATION";
export const TOGGLE_CATEGORY_IS_ANSWERED = "TOGGLE_CATEGORY_IS_ANSWERED";
// Article
export const LOADING_ARTICLE = "LOADING_ARTICLE";
export const ERROR_ARTICLE = "ERROR_ARTICLE";
export const CREATE_ARTICLE = "CREATE_ARTICLE";
export const FETCH_ARTICLE = "FETCH_ARTICLE";
export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const FETCH_SIMILAR_ARTICLES = "FETCH_SIMILAR_ARTICLES";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const DISABLE_ARTICLE = "DISABLE_ARTICLE";
// Banner
export const LOADING_BANNER = "LOADING_BANNER";
export const ERROR_BANNER = "ERROR_BANNER";
export const CREATE_BANNER = "CREATE_BANNER";
export const FETCH_BANNER = "FETCH_BANNER";
export const FETCH_BANNERS = "FETCH_BANNERS";
export const FETCH_BANNERS_IN_REVIEW = "FETCH_BANNERS_IN_REVIEW";
export const EDIT_BANNER = "EDIT_BANNER";
export const DISABLE_BANNER = "DISABLE_BANNER";
export const REVIEW_BANNER = "REVIEW_BANNER";