import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBannersByLocation } from '../../../../store/actions/banner';
import PublicidadeBanner from '../../../../components/PublicidadeBanner';

const PublicBannerOnLocation = ({ BannerParams }) => {
  const dispatch = useDispatch();
  const { list, isLoading, indexOrder } = useSelector((state) => state.banner);
  const banners = indexOrder.map((index) => list[index]);

  const onFetchBanners = useCallback(() => {
    const params = BannerParams.toString();
    dispatch(fetchBannersByLocation(params));
  }, [BannerParams, dispatch]);

  useEffect(() => {
    onFetchBanners();
  }, [onFetchBanners]);

  return (
    <>
      {banners.map((banner, index) => (
        <div className="eight wide tablet five wide computer column" key={index}>
          <PublicidadeBanner data={banner} />
        </div>
      ))}
      {isLoading && (
        <div className="ui active inverted dimmer">
          <div className="ui text loader">Carregando serviços...</div>
        </div>
      )}
      {!isLoading && banners.length === 0 && (
        <div className="sixteen wide column">
          <div className="ui warning message">Nenhum serviço encontrado.</div>
        </div>
      )}
    </>
  );
};

export default PublicBannerOnLocation;
